<template>
  <!--    编辑考试信息-->
  <div class="set-exam--style">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb ref="breadcrumb" class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/manage/exam/list'">考试列表</el-breadcrumb-item>
        <el-breadcrumb-item>考试信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content-wrapper">
      <div class="step-style">
        <el-tabs v-model="step" @tab-click="handleClick">
          <el-tab-pane label="考试设置" name="0" />
          <el-tab-pane label="考题设置" name="1" />
          <el-tab-pane label="考生设置" name="2" />
          <el-tab-pane v-show="type !== 4" label="考试结果" name="3" />
        </el-tabs>
      </div>
      <div
        class="content-step--style"
        :style="(step === '0' ? 'display: flex;align-items: center' : '', step !== '3' ? 'height: calc(100% - 138px);' : '')"
      >
        <FirstStep ref="first" v-show="step === '0'" />
        <SelectPaperMethods ref="second" v-show="step === '1'" />
        <Staff ref="third" v-show="step === '2'" />
        <ExamResult ref="four" v-show="step === '3'" />
      </div>
      <div class="content-footer yt-flex-layout flexCenter" v-show="step !== '3'">
        <el-button class="el-button--default btn" @click="$router.back()">返回</el-button>
        <el-button type="primary" class="button" @click="saveSetting(step)">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FirstStep from '@components/manage/exam/exam/newPaper/FirstStep'
import SelectPaperMethods from '@components/manage/exam/exam/newPaper/SelectPaperMethods'
import Staff from '@components/manage/exam/exam/newPaper/InternalStaff'
import ExamResult from '@components/manage/exam/list/ExamResult'
export default {
  name: 'SettingExam',
  components: { FirstStep, SelectPaperMethods, Staff, ExamResult },
  data() {
    return {
      examType: 0,
      paperId: '',
      timer: null,
      type: 0,
      step: 0
    }
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  created() {
    let params = this.$handleParams('settingExamData')
    this.paperId = params.examId
    this.type = Number(params.type) || 0
    this.step = params.stepIndex
    this.handleClick({ name: this.step })
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.handleClick({ name: this.step })
    }, 1000)
  },
  methods: {
    saveSetting(step) {
      // 保存设置
      switch (step) {
        case '0': {
          // 保存考试设置
          this.$refs['first'].submitFirstStep().then(() => {
            this.$message.success('考试信息保存成功')
            this.$router.push('/manage/exam/list')
          })
          return
        }
        case '1': {
          // 保存考试试卷设置
          this.$refs['second'].savePaperStatus().then(() => {
            this.$message.success('试卷保存成功')
            this.$router.push('/manage/exam/list')
          })
          return
        }
        case '2': {
          this.$refs['third'].updateOutsiderData()
          this.$router.push('/manage/exam/list')
          return
        }
        default:
          return
      }
    },
    handleClick(e) {
      if (e.name === '0') {
        // 获取考试信息
        this.$nextTick(() => {
          this.$refs['first'].examInfo(this.paperId)
        })
      }
      if (e.name === '1') {
        this.$nextTick(() => {
          this.$refs['second'].judgeHasPaper(true)
        })
      }
      if (e.name === '2') {
        this.$nextTick(() => {
          this.$refs['third'].determineTheCategory()
        })
      }
      if (e.name === '3') {
        this.$nextTick(() => {
          this.$refs['four'].getPaperResult(this.paperId)
        })
      }
      this.step = e.name
    }
  }
}
</script>

<style lang="less" scoped>
.set-exam--style {
  min-width: 1200px;
  background: #f8f8f8;
  height: 100%;
  .content-wrapper {
    padding: 10px 20px;
    height: 100%;
    .content-footer {
      background: #fff;
      margin-top: 10px;
      height: 78px;
      .btn {
        .wh(120px, 38px);
      }
    }
  }
  .step-style {
    padding-left: 40px;
    background: #fff;
    border-radius: 6px;
    height: 40px;
    margin-bottom: 10px;
    ::v-deep {
      .el-tabs__item.is-active,
      .el-tabs__item:hover {
        color: #448bff;
      }
      .el-tabs__active-bar {
        background: #448bff;
      }
    }
  }
  .content-step--style {
    background: #fff;
    overflow-y: auto;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0 !important;
}
</style>
